import Vue from 'vue';

import "../plugins/service/index.js";
import "@/controllers/Util.controller.js";
import { ENDPOINTS, BASE_URL } from '../plugins/service/Endpoints.js'

// CONFIGURAÇÃO DE COSTUMIZAÇÃO
Vue.prototype.$superingresso = new Vue ({
    data() {
        return {
            ENDPOINTS: ENDPOINTS,
            evento: [],
            apresentacoes: []
        }
    },
    methods: {

        tratarLink(str) { // TODO: tratarLink

            str = str.replace(/ /g, "-")
            str = str.replace(/---/g, "-");
            str = str.replace(/"/g, "");
            str = str.replace(/'/g, "");

            let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
            let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
            let novastr = "";

            for(let i = 0; i < str.length; i++) {
                let troca = false;
                for (let a = 0; a < com_acento.length; a++) {
                    if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                        novastr += sem_acento.substr(a,1);
                        troca = true;
                        break;
                    }
                }
                if (troca == false) {
                    novastr += str.substr(i, 1);
                }
            }

            return novastr.toLowerCase();
        },   
                
        async getEventos() { // TODO: getEventos
            let dados = this.getDados('getEventos');
            return await this.$axios.post('router.php', dados)
        },
        async aceiteTermo(email) { // TODO: getEventos
            let dados = this.getDadosP('BasePessoaClass', 'aceiteTermo', [email]);
            return await this.$axios.post('router.php', dados)
        },
        getApresentacoes(idevento) { // TODO: getApresentacoes
            let dados = this.getDadosP('SuperIngresso', 'getApresentacoes', [idevento]);
            this.$axios.post('router.php', dados)
            .then(response => {
                if(response) {

                    this.apresentacoes = []
                    let datas          = []
                    let res = response

                    res.dates.forEach(item => {
                        datas.push(item.dia)
                    })
                    
                    let apresentacoes = res.apresentacoes.filter((apre) => {
                        let date = apre.dthr_apresentacao.split(' ')[0];
                        if(datas.includes(date)) return apre;
                    });

                    res.apresentacoes = apresentacoes

                    this.apresentacoes = res
                }
            })
        },
        async getApresentacao(idapresentacao) { // TODO: getApresentacao
            //parametro: idapresentacao
            let dados = this.getDadosP('SuperIngresso', 'getApresentacao', [idapresentacao]);
            return await this.$axios.post('router.php', dados)
        },
        async getApresentacoesHorarios(idevento, data) { // TODO: getApresentacao
            let dados = this.getDadosP('SuperIngresso', 'getApresentacoesHorarios', [idevento, data]);
            return await this.$axios.post('router.php', dados)
        },
        async getTipoProdutos(idapresentacao) { // TODO: getTipoProdutos
            //parametro: idapresentacao
            let dados = this.getDadosP('SuperIngresso', 'getProdutos', [idapresentacao, null, { api: true }]);
            return await this.$axios.post('router.php', dados)
        },
        async getProdutos() { // TODO: getProdutos
            //parametros: {{idApresentacao}}, {{idTipoProduto}}, {"api" : true}
            let params = [
                "b20fee2deb926587d41b74341a2e2b63ec5bf985",
                "ddea5d459dbde4dd111ebad5e29a79f66ead2897",
                { "api": true}
            ]
            let dados = this.getDadosP('SuperIngresso', 'getProdutos', params);
            return await this.$axios.post('router.php', dados)
        },
        async setCarrinho(params) { // TODO: setCarrinho
            //parametros: [ {"idproduto": {{idproduto}}, "qtd": 1} ], {{idapresentacao}}, false ]
            // let params = [
            //     { "idproduto": "6dc9db3e19f7cde33c2b25f85be739d97484078b", "qtd": 1 },
            //     "b20fee2deb926587d41b74341a2e2b63ec5bf985",
            //     false
            // ]
            let dados = this.getDadosP('SuperIngresso', 'iniciarReserva', params);
            return await this.$axios.post('router.php', dados)

        },
        async getCarrinhoPage() { // TODO: getCarrinhoPage
            let dados = this.getDados('getCarrinhoPage');
            return await this.$axios.post('router.php', dados)
        },
        async getCarrinhoPageItens(idmovimentacao) { // TODO: getCarrinhoPageItens
            let dados = this.getDadosP('SuperIngresso', 'getCarrinhoPageItens', [idmovimentacao, null, { api: true }]);
            return await this.$axios.post('router.php', dados)
        },
        async cancelarReserva(idMovimentacao) { // TODO: cancelarReserva
            //parametros [{{idMovimentacao}}, null, false, false]
            let params = [
                idMovimentacao, null, false, false
            ]
            let dados = this.getDadosP('SuperIngresso', 'cancelarReserva', params);
            return await this.$axios.post('router.php', dados)
        },
        async cancelarBoleto(idMovimentacao) { // TODO: cancelarBoleto
            let params = [
                idMovimentacao
            ]
            let dados = this.getDadosP('SuperIngresso', 'cancelarBoleto', params);
            return await this.$axios.post('router.php', dados)
        },
        async removeProdutoCarrinho (idmovimentacao, idprod) { // TODO: removeProdutoCarrinho
            let params = [
                idmovimentacao, idprod, false, false
            ]
            let dados = this.getDadosP('SuperIngresso', 'removeProdutoCarrinho', params);
            return await this.$axios.post('router.php', dados)
        },
        cancelarCompra(idmovimentacao, items) { // TODO: cancelarCompra
            //parametros [ {{idmMovimentacao}}, {{arrayItens}}, null, false, false ]
            let params = [ idmovimentacao, items ]
            let dados = this.getDadosP('SuperIngresso', 'cancelaCompra', params);
            this.$axios.post('router.php', dados)
        },
        getTermoCorrente() { // TODO: getTermoCorrente
            let dados = this.getDadosTermos('BaseTermoClass', 'getTermoCorrente', "2");
            this.$axios.post('router.php', dados).then(response => {
                if(response) {
                    //console.log(response)
                }
            })
        },
        async getTermoNovo() { // TODO: getTermoCorrente
            const dados = await this.getDadosP('BaseTermoClass', 'get', [{}]);
            return await this.$axios.post('router.php', dados)
        },
        async setPortadorItem(params) { // TODO: setPortadorItem
            let dados = this.getDadosP('SuperIngresso', 'portadorSetItem', params);
            return await this.$axios.post('router.php', dados)
        },
        
        async trocarVoucher(idLocalizador, recaptcha) { // TODO: trocarVoucher
            let params = [
                idLocalizador,
                recaptcha
            ]
            let dados = this.getDadosP('SuperIngresso', 'iniciarTrocaVoucher', params);
            return await this.$axios.post('router.php', dados)
        },

        async getFormasPagamento(idmovimentacao, protect = null) { // TODO: getFormasPagamento
            let dados = this.getDadosP('SuperIngresso', 'getGatewayPagamento', [idmovimentacao, null, null, null, protect]);
            return await this.$axios.post('router.php', dados)
        },

        async getEnderecosEntrega() { // TODO: getEnderecosEntrega
            let dados = this.getDadosP('BasePessoaEnderecoEntregaClass',  'getEnderecosEntrega');
            return await this.$axios.post('router.php', dados)
        },

        async setEnderecoEntrega(params) { // TODO: setEnderecoEntrega
            let dados = this.getDadosP('BasePessoaEnderecoEntregaClass',  'setEnderecoEntrega', params);
            return await this.$axios.post('router.php', dados)
        },

        addGetInfoUrl(object) { // TODO: addGetInfoUrl
            let url = window.location.href.split("#");

            let urlcomplete = url[0];
            let params = urlcomplete.indexOf('?') > -1 ? "&" : "?";

            for (let i in object) {
                params = params + i + "=" + object[i]
            }

            urlcomplete = urlcomplete + params;
            return urlcomplete;
        },

        async iniciarPagamento(idmovimentacao, idformapagamento, recaptcha, Tipoingresso, parcela) { // TODO: iniciarPagamento
            let param = [
                idmovimentacao,
                idformapagamento,
                this.addGetInfoUrl({ simodalexternaliframe: "closeAll" }),
                { parcela: parcela },
                recaptcha,
                Tipoingresso
            ]
            let dados = this.getDadosP('SuperIngresso', 'iniciarPagamento', param);
            return await this.$axios.post('router.php', dados)
        },
        
        async concluirPagamento(idmovimentacao) { // TODO: concluirPagamento
            let param = [ { idmovimentacao: idmovimentacao } ]
            let dados = this.getDadosP('SuperIngresso', 'concluirPagamento', param);
            return await this.$axios.post('router.php', dados)
        },

        async vincularPortador(idmovimentacaoitem, dadoswallet) { // TODO: vincularPortador
            let dados = this.getDadosP('SuperIngressoWeb', 'elevenWallet', [idmovimentacaoitem, dadoswallet]);
            return await this.$axios.post('router.php', dados)
        },

        async desVincularPortador(idmovimentacaoitem) { // TODO: desVincularPortador
            let dados = this.getDadosP('SuperIngressoWeb', 'removeElevenWallet', [idmovimentacaoitem]);
            return await this.$axios.post('router.php', dados)
        },

        async aplicarCupom(idmovimentacao, cupom) {
            let dados = this.getDadosP('SuperIngresso', 'aplicarCupom', [idmovimentacao, cupom]);
            return await this.$axios.post('router.php', dados);        
        },

        async validarEmail(email) {
            let dados = this.getDadosP('SuperIngressoWeb', 'validaemail', [email, null])
            return await this.$axios.post('router.php', dados); 
        },

        async CadastroPessoa(params) {
            let dados = this.getDadosP('BasePessoaClass', 'setPessoa', params)
            return await this.$axios.post('router.php', dados); 
        },

        post(method) { // TODO: post
            let dados = {
                class: "SuperIngresso",
                method: method,
            }
            this.$axios.post('router.php', dados)
            .then(response => {
                if(response) {
                    return response;
                }
            })
        },
        postParams(classe, method, params) { // TODO: postParams
            let dados = {
                class: classe,
                method: method,
                params: params
            }
            this.$axios.post('router.php', dados)
        },
        getDados(method) { // TODO: getDados
            return {
                class: "SuperIngresso",
                method: method,
            }
        },
        getDadosP(classe, method, params) { // TODO: getDadosP
            return {
                class: classe,
                method: method,
                params: params
            }
        },
        getDadosTermos(argclass, method, params) { // TODO: getDadosTermos
            return {
                class: argclass,
                method: method,
                params: [[params]]
            }
        },

        // busca a url para download do pkpass 
        async getDownloadURLPkPassWallet(tokenIngresso, idmovimentacaoitem) {
            return this.$axios.post('router.php', {
                class: "SuperIngressoWeb",
                method: 'imprimirWallet',
                params: [tokenIngresso, idmovimentacaoitem]
            })
        },
        async getCarrinhoQtdReservados() {
            return this.$axios.post('router.php', {
                class: "SuperIngressoWeb",
                method: 'getCarrinhoQtdReservados',
            })
        }
       
    }
})
