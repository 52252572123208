import { otmizacao } from '../../../package.json'
const TITTLE = otmizacao.titulo
let prefix = '/'
const url = location.href

// A VARIAVEL DO LINK DE POLITICAS ESTA VINDO DO TAG MANAGER COMO 'pt/politicas/cookies'
//ESSA VERIFICAÇÃO FAZ ALTERNAR AS ROTAS DO LINK DE ACORDO COM O CLICK E A LINGUAGEM

if(url.includes("/pt/produto/pt/politicas/cookies")|| 
url.includes("/pt/politicas/pt/politicas/cookies")|| 
url.includes("/pt/produto/es/politicas/cookies")||
url.includes("/pt/politicas/pt/politicas")){

    const newUrl = `pt/politicas/cookies`

    window.location.replace(`/${newUrl}`)
}   

export default [

    {
        path: '/',
        name: 'Home',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "home" */ '@/views/home.vue'),
        meta: { title: TITTLE + 'Ingressos Oficiais', requiresAuth: false }
    },

    {
        path: prefix + 'perfil',
        name: 'Perfil',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "perfil" */ '@/views/perfil.vue'),
        meta: { title: TITTLE + 'Perfil', requiresAuth: false }
    },

    {
        path: prefix + 'checkout',
        name: 'Checkout',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "checkout" */ '@/views/checkout.vue'),
        meta: { title: TITTLE + 'Checkout', requiresAuth: false }
    },

    {
        path: prefix + 'informacoes/:pagina/',
        name: 'Informacoes',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "informacoes" */ '@/views/informacoes.vue'),
        meta: { title: TITTLE + 'Informacoes', requiresAuth: false }
    },

    {
        path: prefix + 'politicas/:pagina/',
        name: 'Politicas',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "politicas" */ '@/views/politicas.vue'),
        meta: { title: TITTLE + 'Políticas', requiresAuth: false }
    },

    {
        path: prefix + 'meuspedidos',
        name: 'MeusPedidos',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "meuspedidos" */ '@/views/meus-pedidos.vue'),
        meta: { title: TITTLE + 'Meus Pedidos', requiresAuth: false }
    },

    {
        path: prefix + 'produto/:id',
        name: 'Produto',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "produto" */ '@/views/pagina-produtos.vue'),
        meta: { title: TITTLE + 'Ingressos Oficiais', requiresAuth: false }
    },

    {
        path: prefix + 'redefinirsenha',
        name: 'Senha',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "redefinirsenha" */ '@/views/recuperacao.vue'),
        meta: {title: TITTLE + 'Redefinir Senha', requiresAuth: false}
    },

    {
        path: prefix + 'validatemail',
        name: 'Confirmar',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "validatemail" */ '@/views/confirm-email.vue'),
        meta: {title: TITTLE + 'Confirmar E-mail', requiresAuth: false}
    },

    {
        path: prefix + 'voucher',
        name: 'Voucher',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "voucher" */ '@/views/voucher.vue'),
        meta: {title: TITTLE + 'Voucher', requiresAuth: false}
    },

    {
        path: '*',
        name: 'Error',
        component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
        meta: { title: TITTLE + 'Error 404', requiresAuth: false }
    }
]
